export const Configuration = {
  backend: {
    login: "/users/login",
    refreshToken: "/users/refreshToken",
    getStatistics: "/statistics/dashboard",
    getReferences: "/references",
    deleteReferences: "/references/{id}",
    getActivities: "/activities",
    editReference: "/references/{id}",
    getProjects: "/projects",
    createProjects: "/projects",
    editProjects: "/projects/{id}",
    getCustomers: "/users",
    deleteCustomer: "/users/{id}",
    editCustomer: "/users/{id}",
    changePassword: "/users/changePassword",
    forgotPassword: "/users/forgotPassword",
    changePasswordFirst: "/users/changePasswordFirst",
    createInvoices: "/invoices",
    getInvoices: "/invoices",
    editInvoices: "/invoices/{id}",
    deleteInvoices: "/invoices/{id}",
    redeemPrice: "/users/redeemPrice",
    getSettings: "/settings",
    updateSettings: "/settings",
    changePasswordTokenCheck: "/users/changePasswordTokenCheck",
    doLogout: "/users/logout",
  },
  routeTypes: {
    admin: "ADMIN",
    customer: "CUSTOMER",
    noAuth: "NOAUTH",
  },
  googleLogin: {
    emailCode: "666111",
  },
  enums: {
    referenceStatus: {
      0: "Pending",
      4: "Pre-Sales",
      5: "Lost",
      1: "Won",
      3: "Inactive",
      2: "Delete",
    },
    referenceStatus1: {
      pending: 0,
      sucess: 1,
      delete: 2,
      deactive: 3,
      presales: 4,
      fails: 5,
    },
    referenceContactUserType: {
      1: "Customer",
      0: "Non-Customer",
    },
    customerStatus: {
      1: "Active",
      0: "Inactive",
      2: "Delete",
    },
    customerStatus1: {
      active: 1,
      inactive: 0,
      delete: 2,
    },
    invoiceStatus: {
      pending: "Pending",
      paid: "Paid",
      delete: "Delete",
    },
    redeemType: {
      cashPayment: 1,
      invoiceDiscount: 2,
    },
    redeemType1: {
      1: "Cash Payment",
      2: "Invoice Discount",
    },
    settingValueType: {
      TEXTFILED: "textFieled",
      TEXTAREA: "textarea",
      TIME: "time",
      INTEGER: "integer",
      FLOAT: "float",
      EMAILADDRESSES: "emailAddresses",
      PERCENTAGE: "percentage",
      PRICE: "price",
    },
    projectStatus: {
      1: "Active",
      0: "Inactive",
      2: "Delete",
    },
  },
};
